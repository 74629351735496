import './App.css';
import us from './img/us/us.png'

function Us() {

  return (
    <div align="center">
      <img src={ us } className="img-class" />
    </div>
  );
}

export default Us;
