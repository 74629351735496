import { useEffect, useState } from 'react';
import './App.css';

import recetario_rollos_de_pollo from './img/recetario-rollos-de-pollo.png'

const PATH_NAME = window.location.pathname

function Recipes() {
  const [ image, setImage ] = useState(null)
  const images = [ 
    { name: "recetario-rollos-de-pollo", image: recetario_rollos_de_pollo }
  ]

  useEffect(() => {
    const found = images.find(({ name }) => name == PATH_NAME.split("/")[2]);
    
    if( found ) setImage( found.image )
  }, []);

  return (
    <div align="center">
      <img src={ image } className="img-class" />
    </div>
  );
}

export default Recipes;
