import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import logo from './img/home/logo.png'
import main from './img/recipe/main.png'
import arrow from './img/recipe/arrow.png'

function HomeRecipes() {
  const PAGES = [
    { title: 'Rollos rellenos de pechuga', path: '/recetas/recetario-rollos-de-pollo' }
  ]

  return (
    <div className='recipe-background'>
      <Navbar className='nav-style' fixed="top" >
        <Container>
          <Navbar.Brand href="/">
            <div> <img src={ logo } className='logo'/> </div>
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Container className='section-class'>
        <Row className='align-items-center'>
          <Col md={ 6 } xs={ 12 }>
            <img src={ main } className='image-home' />
          </Col>
          <Col md={ 6 } xs={ 12 }>
            { PAGES.map( item => (
              <div onClick={ () => window.location.href = item.path } className='item-class'>
                <div className='btn-home'><span>{ item.title }</span> <img src={ arrow } className='arrow' /> </div>
              </div>
            )) }
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeRecipes;
