import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './Home'
import Recipes from './Recipes'
import HomeRecipes from './HomeRecipes'
import Us from "./Us"
import './App.css';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="nosotros" element={<Us />} />
          <Route exact path="recetas" element={<HomeRecipes />} />
          <Route exact path="recetas/*" element={<Recipes />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
