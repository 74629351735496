import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import logo from './img/home/logo.png'
import chilli from './img/home/chilli.png'

function Home() {
  return (
    <div className='home-background'>
      <Navbar className='nav-style' fixed="top" >
        <Container>
          <Navbar.Brand href="/">
            <div> <img src={ logo } className='logo'/> </div>
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Container className='section-class'>
        <Row className='align-items-center'>
          <Col lg={ 7 } md={ 12 } xs={ 12 }>
           <div className="title-home"> BIENVENIDOS </div>
           <Container>
            <Row>
              <Col md={ 4 } xs={ 12 }>
                <div className='btn-home' onClick={ () => window.location.href = 'https://www.venturafoods.com/' }> WEB </div>
              </Col>
              <Col md={ 4 } xs={ 12 }>
                <div className='btn-home' onClick={ () => window.location.href = 'recetas/' }> RECETARIO </div>
              </Col>
              <Col md={ 4 } xs={ 12 }>
                <div className='btn-home' onClick={ () => window.location.href = 'nosotros/' }> CONOCE MAS DE MANGO HABANERO </div>
              </Col>
            </Row>
           </Container>
          </Col>
          <Col lg={ 5 } md={ 12 } xs={ 12 }>
            <img src={ chilli } className='image-home' />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
